import axios from "axios";

class HttpRequestService {

    constructor() {
        
        this._axiosInstance = axios.create({
            headers: { 'Cache-Control': 'no-cache' },
            baseURL: process.env.VUE_APP_URL_API
            //baseURL: "http://localhost:3000"

            //publicPath: "http://localhost:3000/api"
            //publicPath: "https://localhost:3030/api"            
        });        
    }    

    /**
     * Axios instance to make http requests.
     */
    get axios() { return this._axiosInstance; }
}

export default new HttpRequestService();
