import httpRequest from '@/services/httpRequest-service.js';

class QuotationService {

  quotation(name, email, phone, description, recaptchaToken) {
    return httpRequest.axios.post('api/quotation',
      {
        name: name,
        email: email,
        phone: phone,
        description: description,
        recaptchaToken: recaptchaToken,        
      });
  }
}

export default new QuotationService();
