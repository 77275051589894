import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import infiniteScroll from 'vue-infinite-scroll'


Vue.config.productionTip = false


// Variável gloval para verificar se os layouts foram carregados
var shared = new Vue({
  data:{
    layoutInternal: "not-created",
    layoutDefault: "not-created"
  }
})

shared.install = function(){
  Object.defineProperty(Vue.prototype, '$myGlobalCheckCreated', {
    get () { return shared }
  })
}
Vue.use(shared);
/* ************ */

Vue.use(Vuelidate)
Vue.use(infiniteScroll)



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
