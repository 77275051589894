<template>
  <!-- <button @click="disparaAcao()" class="botao" :class="estiloDoBotao" :type="tipo">{{ rotulo }}</button> -->
  <button
    @click="onClick"
    class="btn"
    :class="classObject"
    type="button">
      <svg v-if="loading" class="icon icon-small icon-with-text icon-loading rotate">
        <use xlink:href="/images/icon-lib.svg#icon-loading" />
      </svg>
      <span>{{ rotulo }}</span>
    </button>
</template>


<script>
export default {
  props: {
    rotulo: String,
    btnStyle: String,
    btnSize: String,
    loading: {
      type: Boolean,
      required: false,
    },
  },

  methods: {
    onClick: function() {
      this.$emit("click");
    }
  },

  computed: {
    classObject: function() {
      return {
        "btn-primary": this.btnStyle === "primary",
        "btn-primary-outline": this.btnStyle === "primary-outline",
        "btn-secondary": this.btnStyle === "secondary",
        "btn-secondary-outline": this.btnStyle === "secondary-outline",
        "btn-small": this.btnSize === "small"
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

$accent-color: #ef6c00;

%boxshadow {
  box-shadow: 6px 6px 29px -16px rgba(0, 0, 0, 0.24);
}
%boxshadow-float-2 {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

%boxshadow-float {
  box-shadow: 6px 6px 29px -16px rgba(0, 0, 0, 0.8);
}

%boxshadow-button {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

%boxshadow-button-2 {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.btn {
  @extend %boxshadow-button;
  display: inline-block;  
  min-width: 64px;
  padding: .913rem 2rem;
  margin: 0.375rem;
  border: 0;
  cursor: pointer;

  font-weight: 500;
  font-size: .875rem;
  text-transform: uppercase;
  white-space: normal;
  word-wrap: break-word;
  color: #fff;
  border-radius: 26px;
  transition: all 0.3s;

  @media (max-width: $screenSmall) {
    width: 100%;
    margin: 0;
  }

}

.btn:disabled,
.btn.disabled {
  cursor: no-drop;
}

.btn:active,
.btn:focus,
.btn:hover {
  @extend %boxshadow-button-2;

  outline: 0;
}

.btn-primary {
  background-color: $accent-color;
  color: #fff;
}
.btn-primary:hover {
  color: lighten(white, 5%);
  background-color: lighten($accent-color, 5%);
}
.btn-primary:not([disabled]):not(.disabled).active,
.btn-primary:not([disabled]):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  @extend %boxshadow-button-2;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: darken(white, 5%);
  background-color: darken($accent-color, 5%);
}

.btn-primary:disabled {
  color: $graffiti-400!important;
  background-color: lighten($graffiti-100, 10%) !important;
  border-color: #0062cc;

  box-shadow: none;
}

.btn-small {
  padding: 0.907rem 3rem;
  font-size: 14px;
}
</style>
