export const sendEventAnalytics = {

    methods: {
        sendEventAnalytics: function (event, category, action, label, value) {
            this.$gtm.trackEvent({
                event: event, // Event type [default = 'interaction'] (Optional)
                category: category,
                action: action,
                label: label,
                value: value,
                noninteraction: false // Optional
            });
        }
    }
}
