<template>
    <transition name="fade" mode="out-in">
  <component 
    :is="layout" 
    :title="title" 
    :introduction="introduction"
    :bgHeader="bgHeader"
  >
      <router-view 
        :layout.sync="layout"
        :title.sync="title"
        :introduction.sync="introduction"
        :bgHeader.sync="bgHeader"
      />
  </component>
    </transition>
</template>

<script>
// @ is an alias to /src
//import MenuPrincipal from '@/components/shared/menu/menu-principal.vue'

/* export default {
  name: 'home',
  components: {
    MenuPrincipal

  }
} */
export default {
  name: `App`,
  data() {
    return {
      layout: `div`,
      title: '',
      introduction: '',
      bgHeader: '',
    };
  },

};


</script>

<style lang="scss">

@import "@/styles/_variables.scss";

@import "@/styles/bootstrap/bootstrap-reboot.scss";
@import "@/styles/bootstrap/bootstrap-grid.scss";

// @import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

@import "@/styles/global.scss";
@import "@/styles/colors.scss";

@import "@/styles/animations.scss";



// colocar variáveis de forma global
// NÃO FUNCIONOU
// https://vueschool.io/articles/vuejs-tutorials/globally-load-sass-into-your-vue-js-applications/


</style>
