<template>
  <div class="LayoutInternal">
    <header :class="getBgHeader">
      <Navbar 
        :layoutInternal="layoutInternal"
      />

      <div class="content-hero">
        <div class="container">
          <h1 class="fade-in" id="pageTitle">{{ title }}</h1>
          <p
            class="introduction"
          >{{ introduction }}</p>          
        </div>
      </div>
      <!-- content-hero end -->
    </header>
    <main class="LayoutInternal__main">
      <transition name="fade" mode="out-in">
      <slot />
      </transition>
    </main>

    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/shared/menu/navbar.vue";
import Footer from "@/components/shared/footer/footer.vue";

import { goToElement } from "@/mixin/go-to-element-mixin.js";

export default {
  mixins: [goToElement],

  components: {
    Navbar,
    Footer,
  },  

  name: `LayoutInternal`,

  props: {
    title: String,
    introduction: String,
    bgHeader: String,
  },

  data() {
    return {
      user: null,      
      layoutInternal: true,         
    };
  },
  created() {

    this.$myGlobalCheckCreated.layoutInternal = 'created';
    this.$myGlobalCheckCreated.layoutDefault = 'not-created';

    //console.log(this.$myGlobalCheckCreated.layoutInternal, 'this.$myGlobalCheckCreated.layoutInternal - layoutInternal')

    // Simulate fetching user data.
    setTimeout(() => {
      this.user = { name: `John Doe` };
    }, 2000),
      this.$nextTick(function () {
        // lazy
        //console.warn(this.$scopedSlots.default()[0].context.$refs)
      });
  },
  computed: {
    getBgHeader : function () {
      return {
        'bg-color-blue-500' : this.bgHeader === 'blue',
        'bg-color-green-500' : this.bgHeader === 'green',
        'bg-color-red-500' : this.bgHeader === 'red',
        'bg-color-black' : this.bgHeader === 'black',
      }
    }
  }

};
</script>

<style lang="scss">
@import "@/styles/_variables.scss";


    #app {
        display: flex;
        flex-direction: column;
        height: inherit;

        //AVALIAR SE VAI QUEBRAR O SITE EM ALGUM LOCAL
        // - Está quebrando resoluções menores que 1320px
        //min-width: @PageWidth + 40px;

        main {
            flex: 1 0 auto;
        }

        >*:last-child {
            flex-shrink: 0;
        }
    }



.LayoutInternal {

  //main com a área disponível entre o header e footer
  display: flex;
  flex-direction: column;
  height: inherit;

  main {
      flex: 1 0 auto;
  }

  >*:last-child {
      flex-shrink: 0;
  }

  //main com a área disponível entre o header e footer

  header {
    display: flex;
    flex-direction: column;
    height: 40vh;
    min-height: 40vh;
    max-height: 400px;        

    @media (min-width: $screenLarge) {
      height: 50vh;
    }
  }
  .content-hero {
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    text-align: center;
    color: white;

    .introduction {      
      font-size: $BodyText2;
      margin: auto;      

      @media (min-width: $screenLarge) {
        max-width: 665px;        
        font-size: 1.125rem;
      }
    }
  }

  h1 {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 26px;
    transition: all 0.3s;

    @media (min-width: $screenLarge) {
      margin-bottom: .5rem;
      font-size: 2rem;
      line-height: 32px;
    }
  }

}
</style>

