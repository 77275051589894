<template>
  <footer class="LayoutDefault__footer">
    <div class="container">
      <div class="row">
        <div class="col">
          <a href="/" class="footerbar-brand">
            <svg
              version="1.1"
              class="brand"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 431.89 236.38"
            >
              <path
                class="brand-path"
                d="M281.12,102.16V32.59H411.29L431.89.28V0H248.53V102.16ZM106.23,182h2.18v15h-2.18Zm71.1,0,31.6,7.19L240.56,182v15h-2.18V184.23L209,191l-29.44-6.76v12.69h-2.18V182ZM42.47,186.9H87v1.77H42.47v8.25H40.29V182h61.23v1.77h-59Zm72.63,8.24h55.21v1.78H112.93V182h2.17ZM0,236.38H281.12V159.05H248.53v44.74H32.59v-120l85.86,86.73,85.34-86.73V146.9h135L359,114.31H236.38V0L117.93,120.34,0,0Z"
              />
            </svg>
          </a>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-group">
          <p class="copyright">
            Instalação de Películas e Adesivos<br />
            CNPJ: 28.184.644/0001-04
            <br />© Copyright {{ currentYear }} - MF Film
          </p>
          <div class="social-content">
            <a
              class="social-item"
              href="https://www.facebook.com/MFFILME"
              title="Facebook"
              target="_blank"
            >
              <svg class="icon icon-small icon-facebook">
                <use xlink:href="/images/icon-lib.svg#icon-facebook" />
              </svg>
            </a>
            <!-- <a class="social-item" href title="LinkedIn" target="_blank">
              <svg class="icon icon-small icon-linkedin">
                <use xlink:href="/images/icon-lib.svg#icon-linkedin" />
              </svg>
            </a> -->
            <a
              class="social-item"
              href="https://www.instagram.com/mf.filme/"
              title="Instagram"
              target="_blank"
            >
              <svg class="icon icon-small icon-instagram">
                <use xlink:href="/images/icon-lib.svg#icon-instagram" />
              </svg>
            </a>
          </div>
        </div>
        <div class="col-group col-md-6 col-lg-3">
          <div class="list-group">
            <router-link
              :to="{ name: 'quotation' }"
              class="list-group-item list-group-item-title"
              >Cotação</router-link
            >
            <router-link :to="{ name: 'about' }" class="list-group-item"
              >Quem Somos</router-link
            >
            <router-link
              class="list-group-item"
              :to="{ name: 'insulfilmJanela' }"
              >Insulfilm para Janela</router-link
            >

            <router-link
              class="list-group-item"
              :to="{ name: 'insulfilmProtecaoSolarJanela' }"
              >Insulfilm de proteção solar para janela</router-link
            >
            <router-link
              class="list-group-item"
              :to="{ name: 'insulfilmSegurancaJanela' }"
              >Insulfilm de segurança para Janelas</router-link
            >
            <router-link
              class="list-group-item"
              :to="{ name: 'insulfilmDecorativoJanela' }"
              >Insulfilm decorativo para janelas</router-link
            >
            <router-link
              class="list-group-item"
              :to="{ name: 'insulfilmPrivacidadeJanela' }"
              >Insulfilm de privacidade para janelas</router-link
            >
            <router-link
              class="list-group-item"
              :to="{ name: 'insulfilmServicosSacadas' }"
              >Insulfilm para Sacadas</router-link
            >
            <router-link
              class="list-group-item"
              :to="{ name: 'insulfilmServicosVitrines' }"
              >Insulfilm para Vitrines</router-link
            >
            <router-link
              class="list-group-item"
              :to="{ name: 'insulfilmServicosGuaritasPortarias' }"
              >Insulfilm para Guaritas e Portarias</router-link
            >
            <router-link
              class="list-group-item"
              :to="{ name: 'insulfilmServicosPortas' }"
              >Insulfilm para Portas</router-link
            >

            <router-link :to="{ name: 'work' }" class="list-group-item"
              >Nossos Trabalhos</router-link
            >
          </div>
        </div>

        <div class="col-group col-md-6 col-lg-3">
          <div class="list-group">
            <div class="list-group-item list-group-item-title">Películas</div>
            <router-link
              :to="{ name: 'filmIntercontrol' }"
              class="list-group-item"
              >Películas InterControl</router-link
            >
            <router-link :to="{ name: 'film3m' }" class="list-group-item"
              >Películas 3M</router-link
            >
            <router-link :to="{ name: 'filmSunTek' }" class="list-group-item"
              >Películas SunTek</router-link
            >
          </div>
        </div>

        <div class="col-group col-md-6 col-lg-3">
          <div class="list-group">
            <div class="list-group-item list-group-item-title">Contato</div>
            <a class="list-group-item" href="mailto:contato@mffilm.com.br"
              >contato@mffilm.com.br</a
            >
            <div class="list-group-item">(11) 4229 2015 ou (11) 99920 7455</div>
            <div class="list-group-item">
              Rua Santa Rosa, 289 - São Caetano do Sul - São Paulo.
            </div>
          </div>
        </div>
      </div>
      <!-- row end -->
    </div>
    <!-- container end -->
  </footer>
</template>

<script>
let getCurrentYear = new Date().getFullYear();

export default {
  data: function () {
    return {
      currentYear: getCurrentYear,
    };
  },
};
</script>


<style lang="scss">
@import "@/styles/_variables.scss";

footer {
  padding: 2rem 0;
  font-size: $BodyText2;
  font-weight: 300;
  background-color: $black-900;
  color: #858585;

  a {
    color: #858585;

    &:hover {
      color: #fff;
    }
  }
}

.footerbar-brand {
  &:hover .brand-path {
    fill: #fff;
  }

  .brand {
    width: 70px;
    height: 38px;
    margin-bottom: 0.75rem;
    vertical-align: middle;

    @media (min-width: $screenLarge) {
      width: 75px;
      height: 39px;
    }
  }
  .brand-path {
    fill: #858585;
    transition: all 0.3s;
  }
}

.col-group {
  margin-bottom: 2rem;
}

.social-item {
  margin-right: 1.75rem;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.4rem 0rem;
  text-decoration: none;
}

.list-group-item-title {
  font-weight: 500;
  color: #fff;
}
</style>