export const goToElement = {

    methods: {
        // position:    "start" | "center" | "end" | "nearest"        
/*         goToElement(refObj, position) {
            console.warn(this)
            console.log(this.$scopedSlots)
            refObj.scrollIntoView({ behavior: "smooth", block: position });
        } */
        goToElement(refName, position) {
            var element = this.$refs[refName];
            element.scrollIntoView({ behavior: "smooth", block: position });
        }
    }

}