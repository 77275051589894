import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '@/views/Home.vue'
import Quotation from '@/views/quotation.vue'
import VueGtm from "vue-gtm";

Vue.use(VueRouter)

Vue.use(VueGtm, {
  id: "GTM-KB4HV5D", // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
/*   queryParams: {
    // Add url query string when load gtm.js with GTM ID (optional)
    gtm_auth: "AB7cDEf3GHIjkl-MnOP8qr",
    gtm_preview: "env-4",
    gtm_cookies_win: "x",
  }, */
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: ["homepage"], // Don't trigger events for specified router names (case insensitive) (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { 
      title: 'Aplicação de Películas e Adesivos | MF Film' }, 
      component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')
  },
  
  /* rota continua aparecedno no analytics com origem do ads  */
  {
    path: '/index.shtml',
    name: 'homeIndexShtml',
    meta: { title: 'Aplicação de Películas e Adesivos | MF Film' }, 
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')
  },
  {
    path: '/mf',
    name: 'homeMf',
    meta: { title: 'Aplicação de Películas e Adesivos | MF Film' }, 
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')
  },
  {
    path: '/mf/*',
    name: 'homeMfInternas',
    meta: { title: 'Aplicação de Películas e Adesivos | MF Film' }, 
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')
  },
  /* rota continua aparecedno no analytics com origem do ads  */

  {
    path: '/cotacao',
    name: 'quotation',
    meta: { 
      title: 'Cotação para instalar Películas e Adesivos | MF Film',
      description: 'Faça uma cotação para aplicar películas (insulfilm) em janelas, sacadas, guaritas, portas, divisórias. Cotação para aplicar películas sem compromisso!'
    },
    component: Quotation
  },

  {
    path: '/sobre-nos',
    name: 'about',
    meta: { 
      title: 'Conheça a MF Instalação de Películas e Adesivos | MF Film',      
      description: 'Aplique películas e adesivos com a MF Film.  mais de 18 anos de experiência com profissionais especializados na aplicação de Películas e Adesivos'
    },
    component: () => import(/* webpackChunkName: "about-new" */ '@/views/about-new.vue')
  },
  {
    path: '/trabalhos',
    name: 'work',
    meta: { 
      title: 'Películas e adesivos aplicados em janelas e muito mais | MF Film',
      description: 'Conheça o nosso trabalho de aplicação de películas (insulfilm) em janelas, sacadas, guaritas, portas, divisórias. Cotação sem compromisso!'       
    }, 
    component: () => import(/* webpackChunkName: "work" */ '@/views/work.vue')
  },
  {
    path: '/trabalhos/pelicula-envelopamento-elevador',
    name: 'lift',
    meta: { 
      title: 'Películas e Adesivos para elevadores | MF Film',
      description: 'Aplique películas ou faça o envelopamento de elevadores. Decoração, proteção e estética garantida. Faça uma cotação sem compromisso!' 
    }, 
    component: () => import(/* webpackChunkName: "work" */ '@/views/work.vue')
  },
  {
    path: '/trabalhos/pelicula-adesivo-para-janela',
    name: 'window',
    meta: { 
      title: 'Películas e Adesivos para janelas | MF Film',
      description: 'Instale películas (Insulfilm) em janelas para proteção solar, privacidade e segurança. Proteja objetos do sol e mantenha o clima agradável. Faça uma Cotação!'
    }, 
    component: () => import(/* webpackChunkName: "work" */ '@/views/work.vue')
  },
  {
    path: '/trabalhos/pelicula-para-guarita',
    name: 'guardhouse',
    meta: { 
      title: 'Películas e Adesivos para guaritas | MF Film',
      description: 'Instale películas (Insulfilm) em guaritas para privacidade, decoração e proteção solar. Faça uma cotação sem compromisso!'      
    }, 
    component: () => import(/* webpackChunkName: "work" */ '@/views/work.vue')
  },
  {
    path: '/trabalhos/envelopamento-geladeiras-eletrodomesticos',
    name: 'glass',
    meta: { 
      title: 'Envelopamento de geladeiras e eletrodomésticos | MF Film',
      description: 'Envelope geladeiras e eletrodomésticos. Renove a aparência e proteja o refrigerador de sujeiras e arranhões. Faça uma cotação sem compromisso!'      
    }, 
    component: () => import(/* webpackChunkName: "work" */ '@/views/work.vue')
  },
  {
    path: '/trabalhos/pelicula-adesivo-para-tetos-paredes',
    name: 'roof',
    meta: { 
      title: 'Películas e Adesivos para tetos e paredes | MF Film',
      description: 'Instale películas (Insulfilm) em tetos e paredes. Proteção solar e decoração de ambientes com qualidade, segurança e praticidade. Faça uma Cotação!'
    }, 
    component: () => import(/* webpackChunkName: "work" */ '@/views/work.vue')
  },
  {
    path: '/trabalhos/pelicula-adesivo-para-portas-divisorias',
    name: 'door',
    meta: { 
      title: 'Películas e Adesivos para portas e divisórias | MF Film',
      description: 'Aplique películas (Insulfilm) e adesivos em portas e divisórias para fornecer privacidade e renovar o ambiente. Faça uma cotação sem compromisso!'      
    }, 
    component: () => import(/* webpackChunkName: "work" */ '@/views/work.vue')
  },
  {
    path: '/trabalhos/pelicula-para-varandas-sacadas',
    name: 'balcony',
    meta: { 
      title: 'Películas e Adesivos para sacadas | MF Film',
      description: 'Aplique películas (Insulfilm) em varandas e sacadas para proteger os móveis e objetos do sol sem descaracterizar a fachada. Faça uma Cotação!'      
    }, 
    component: () => import(/* webpackChunkName: "work" */ '@/views/work.vue')
  },
  {
    path: '/trabalhos/pelicula-para-vitrines',
    name: 'showcase',
    meta: { 
      title: 'Películas e Adesivos para vitrines | MF Film',
      description: 'Instale películas (Insulfilm) na sua vitrine para proteger os produtos do sol e preservar a visibilidade. Não perca suas mercadorias, faça uma Cotação!'      
    }, 
    component: () => import(/* webpackChunkName: "work" */ '@/views/work.vue')
  },
  {
    path: '/nossos-trabalhos/pelicula/vitrines',
    name: 'showcaseOld',
    meta: { 
      title: 'Películas e Adesivos para vitrines | MF Film',
      description: 'Instale películas (Insulfilm) na sua vitrine para proteger os produtos do sol e preservar a visibilidade. Não perca suas mercadorias, faça uma Cotação!'      
    }, 
    component: () => import(/* webpackChunkName: "work" */ '@/views/work.vue')
  },

  {
    path: '/pelicula-intercontroll',
    name: 'filmIntercontrol',
    meta: { 
      title: 'Instalação de Películas InterControl | MF Film',
      description: 'Aplicação de películas InterControl em janelas, sacadas, guaritas, portas, divisórias. Faça uma cotação para instalar películas InterControl!'
    },
    component: () => import(/* webpackChunkName: "filmIntercontrol" */ '@/views/film/film-intercontrol.vue')
  },

  {
    path: '/pelicula-3m',
    name: 'film3m',
    meta: { 
      title: 'Instalação de Películas 3M | MF Film',
      description: 'Aplicação de películas 3M em janelas, sacadas, guaritas, portas, divisórias. Faça uma cotação para instalar películas 3M!'
    },
    component: () => import(/* webpackChunkName: "film3m" */ '@/views/film/film-3m.vue')
  },

  {
    path: '/pelicula-suntek',
    name: 'filmSunTek',
    meta: { 
      title: 'Instalação de Películas SunTek | MF Film',
      description: 'Aplicação de películas SunTek em janelas, sacadas, guaritas, portas, divisórias. Faça uma cotação para instalar películas SunTek!'
    },
    component: () => import(/* webpackChunkName: "filmSunTek" */ '@/views/film/film-suntek.vue')
  },

  {
    path: '/pelicula-insulfilm-para-janela',
    name: 'insulfilmJanela',
    meta: { 
      title: 'Instalação de Películas (insulfilm) para Janela | MF Film',
      description: 'Aplique películas (insulfilm) em janelas para proteção solar, resistência dos vidros, proteção de objetos do sol, decoração e privacidade. Faça uma cotação!'
    },      
    component: () => import(/* webpackChunkName: "insulfilmJanela" */ '@/views/location/janela/insulfilm-janela.vue')    
  },

  {
    path: '/qual-melhor-insulfilm-para-colocar-na-janela',
    name: 'insulfilmMelhorJanela',
    meta: { 
      title: 'Qual a melhor insulfilm para colocar na janela? | MF Film',      
      description: 'Conheça a melhor insulfilm para colocar na janela indicada para sua necessidade. Insulfilme profissional e de Alta Performance. Faça uma cotação!'
    },      
    component: () => import(/* webpackChunkName: "insulfilmMelhorJanela" */ '@/views/location/janela/insulfilm-melhor-janela.vue')    
  },
  {
    path: '/qual-melhor-insulfilm-para-privacidade-de-janelas',
    name: 'insulfilmMelhorPrivacidadeJanela',
    meta: { 
      title: 'Qual o melhor insulfilm para privacidade de janelas? | MF Film',      
      description: 'Conheça o melhor insulfilm para para privacidade de janelas. Precisa aplicar insulfilm nas janelas para privacidade? Faça uma cotação!'
    },      
    component: () => import(/* webpackChunkName: "insulfilmMelhorPrivacidadeJanela" */ '@/views/location/janela/insulfilm-melhor-privacidade-janela.vue')    
  },
  {
    path: '/insulfilm-para-janelas-de-apartamento',
    name: 'insulfilmApartamentoJanela',
    meta: { 
      title: 'Insulfilm para janelas de apartamento | MF Film',      
      description: 'Aplique películas (insulfilm) em janelas de apartamento. Proteja os móveis do sol e deixe o ambiente mais agradável. Faça uma cotação!'

      
    },      
    component: () => import(/* webpackChunkName: "insulfilmApartamentoJanela" */ '@/views/location/janela/insulfilm-apartamento-janela.vue')    
  },

  {
    path: '/pelicula-insulfilm-protecao-solar-para-janela',
    name: 'insulfilmProtecaoSolarJanela',
    meta: { 
      title: 'Instalação de insulfilm de Proteção Solar para Janela | MF Film',
      description: 'Aplique películas (insulfilm) em janelas para proteção solar. Proteja os móveis do sol e deixe o ambiente mais agradável. Faça uma cotação!'
    },
    component: () => import(/* webpackChunkName: "insulfilmProtecaoSolarJanela" */ '@/views/location/janela/insulfilm-protecao-solar-janela.vue')    
  },
  {
    path: '/pelicula-insulfilm-seguranca-para-janela',
    name: 'insulfilmSegurancaJanela',
    meta: { 
      title: 'Aplicação de Películas (insulfilm) de Segurança para Janela | MF Film',
      description: 'Aplique películas (insulfilm) de segurança em janelas para tornar os vidros mais resistentes, evitando danos, acidentes e vandalismo. Faça uma cotação!'
    },      
    component: () => import(/* webpackChunkName: "insulfilmSegurancaJanela" */ '@/views/location/janela/insulfilm-seguranca-janela.vue')
  },
  {
    path: '/pelicula-insulfilm-decorativo-para-janela',
    name: 'insulfilmDecorativoJanela',
    meta: { 
      title: 'Instalação de Películas (insulfilm) decorativo para Janela | MF Film',
      description: 'Instale películas (insulfilm) decorativo nas janelas para customizar e decorar os vidros, proporcionando conforto e estilo para o ambiente. Faça uma cotação!'
    },      
    component: () => import(/* webpackChunkName: "insulfilmDecorativoJanela" */ '@/views/location/janela/insulfilm-decorativo-janela.vue')
  },
  {
    path: '/pelicula-insulfilm-privacidade-para-janela',
    name: 'insulfilmPrivacidadeJanela',
    meta: { 
      title: 'Instalação de Películas (insulfilm) privacidade para Janela | MF Film',
      description: 'Instale películas (insulfilm) de privacidade nas janelas. Modelo preto (Blackout), branco (White out) e jateado. Faça uma cotação!'
    },      
    component: () => import(/* webpackChunkName: "insulfilmPrivacidadeJanela" */ '@/views/location/janela/insulfilm-privacidade-janela.vue')
  },
  {
    path: '/pelicula-insulfilm-para-sacadas',
    name: 'insulfilmServicosSacadas',
    meta: { 
      title: 'Instalação de Películas (insulfilm) para Sacada | MF Film',
      description: 'Instale películas (insulfilm) nas sacadas. Insulfilm transparente para proteção dos móveis do sol e prevenção de acidentes. Faça uma cotação!'
    },      
    component: () => import(/* webpackChunkName: "insulfilmServicosSacadas" */ '@/views/location/services/insulfilm-sacadas.vue')
  },
  {
    path: '/pelicula-insulfilm-para-vitrines',
    name: 'insulfilmServicosVitrines',
    meta: { 
      title: 'Instalação de Películas (insulfilm) para Vitrines | MF Film',
      description: 'Instale películas (insulfilm) nas vitrines. Insulfilm transparente para proteger produtos do sol em estabelecimentos comerciais. Faça uma cotação!'
    },      
    component: () => import(/* webpackChunkName: "insulfilmServicosVitrines" */ '@/views/location/services/insulfilm-vitrines.vue')
  },
  {
    path: '/pelicula-insulfilm-para-guaritas-portarias',
    name: 'insulfilmServicosGuaritasPortarias',
    meta: { 
      title: 'Instalação de Películas (insulfilm) em Guaritas e Portarias | MF Film',
      description: 'Instale películas (insulfilm) nas Guaritas e Portarias. Redução do calor, privacidade, segurança e economia com ar condicionado. Faça uma cotação!'
    },      
    component: () => import(/* webpackChunkName: "insulfilmServicosGuaritasPortarias" */ '@/views/location/services/insulfilm-guaritas-portarias.vue')
  },
  {
    path: '/pelicula-insulfilm-para-portas',
    name: 'insulfilmServicosPortas',
    meta: { 
      title: 'Instalação de Películas (insulfilm) para portas | MF Film',
      description: 'Instale películas (insulfilm) nas portas de vidro para privacidade, decoração e segurança. Aplicação comercial e residencial. Faça uma cotação!'
    },      
    component: () => import(/* webpackChunkName: "insulfilmServicosPortas" */ '@/views/location/services/insulfilm-portas.vue')
  },
  {
    path: '/pelicula-insulfilm-automotivo',
    name: 'insulfilmAutomotivo',
    meta: { 
      title: 'Instalação de Películas (insulfilm) automotivo | MF Film',
      description: 'Instale películas (insulfilm) no seu carro para segurança/antivandalismo, estética e proteção solar. Conheça os tipos e insulfilm e faça uma cotação!'
    },      
    component: () => import(/* webpackChunkName: "insulfilmAutomotivo" */ '@/views/location/automotivo/insulfilm-automotivo.vue')
  },

  { 
    path: '*', 
    component: () => import(/* webpackChunkName: "not-found" */ '@/views/not-found.vue')    
  }

]

const router = new VueRouter({
  mode: 'history',

  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },

  base: process.env.BASE_URL,
  routes
})

const DEFAULT_TITLE = 'Aplicação de Películas e Adesivos | MF Film';
const DEFAULT_DESCRIPTION = 'Instalação de películas (insulfilm) e adesivos em janelas, sacadas, guaritas, portas, divisórias para proteção solar, privacidade, decoração e segurança.';
router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
        document.querySelector('meta[name="description"]').setAttribute("content", to.meta.description || DEFAULT_DESCRIPTION);
    });
});


export default router
