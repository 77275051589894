<template>
  <div>
    <div class="container" v-if="serverError">
      <PageAlert imgName = "img-erro.svg">
              Houve um <strong>erro inesperado </strong> ao enviar o formulário. Tente
        novamente mais tarde
      </PageAlert>

      <QuotationContacts />
    </div>

    <div 
      v-if="!formSuccess && !serverError"
      class="cotacao-container"
    >
      <div class="form-cotacao-container">
        <form           
          class="form-cotacao text-left"
        >
          <div 
            class="form-group"          
          >
            <InputText
              v-model.trim="$v.inputName.$model"
              id="inputName"
              type="text"
              label="Nome"
              placeholder="Digite seu nome"
              icon="icon-single-neural"
              :class="{ 'form-control--error' : $v.inputName.$error }"
            />
            <div class="error" v-if="!$v.inputName.required && $v.inputName.$error">O nome é obrigatório</div>
            <div
              class="error"
              v-if="!$v.inputName.minLength && $v.inputName.$error"
            >O nome precisa ter no mínimo {{$v.inputName.$params.minLength.min}} letras</div>
          </div>


          <div class="form-group">
            <InputText
              v-model.trim="$v.inputEmail.$model"
              id="inputEmail"
              type="email"
              label="E-mail"
              placeholder="Digite seu e-mail"
              icon="icon-email-action-unread"
              :class="{ 'form-control--error' : $v.inputEmail.$error }"
            />
            <div class="error" v-if="!$v.inputEmail.required && $v.inputEmail.$error">O e-mail é obrigatório</div>
            <div
              class="error"
              v-if="!$v.inputEmail.email && $v.inputEmail.$error"
            >O e-mail é inválido</div>        
          </div>

          
          <div class="form-group">
            <InputText
              v-model.trim="$v.inputPhone.$model"
              id="inputPhone"
              type="number"
              label="Telefone"
              placeholder="Digite seu telefone"
              icon="phone"
              :class="{ 'form-control--error' : $v.inputPhone.$error }"
            />
            <div class="error" v-if="!$v.inputPhone.required && $v.inputPhone.$error">O telefone é obrigatório</div>
            <div
                class="error"
                v-if="!$v.inputPhone.minLength && $v.inputPhone.$error"
              >O telefone precisa precisa ter no mínimo {{$v.inputPhone.$params.minLength.min}} números</div>
          </div>
  <!--         <pre>
            {{ $v.inputDescription }}
          </pre> -->
          <div class="form-group">
            <InputTextArea
              v-model.trim="$v.inputDescription.$model"
              id="inputDescription"
              label="Medidas e o Local da Aplicação"
              placeholder="Digite o local para aplicação da película, a sua necessidade e as medidas se você tiver."
              rows="5"
              :class="{ 'form-control--error' : $v.inputDescription.$error }"
            />
            <div class="error" v-if="!$v.inputDescription.required && $v.inputDescription.$error">As medidas e o local é obrigatório</div>

            <div
              class="error"
              v-if="!$v.inputDescription.minLength && $v.inputDescription.$error"
            >As medidas e o local precisa ter no mínimo {{$v.inputDescription.$params.minLength.min}} letras</div>

          </div>

          <div class="form-action">
            <ButtonDefault
              class="button"
              rotulo="Enviar"
              btnStyle="primary"
              btnSize="small"
              @click.native="sendQuotation"
              :disabled="$v.$invalid || loading"
              :loading="loading"
            />
          </div>
          <!-- <pre>{{ $v }}</pre> -->
        </form>
      </div>
      <div class="contacts-container">
        <QuotationContacts />
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <div v-if="formSuccess && !serverError" ref="response-message" class="response-message success fade-in">
        <div class="response-illustration">
          <svg class="icon icon-check-double">
            <use xlink:href="/images/icon-lib.svg#icon-check-double" />
          </svg>
        </div>
        <div class="response-title">Pedido de cotação recebido!</div>
        <p>
          <strong>{{ inputName }}</strong>, iremos responder o mais breve
          possível.
        </p>
      </div>
    </transition>

    <p v-if="!formSuccess" class="atendimento-local">
      Atendemos
      <strong>São Paulo</strong>, Grande São Paulo, interior e baixada Santista.
      O horário de atendimento é de segunda a sexta em horário comercial.
    </p>

    <template>
      <vue-recaptcha
        ref="recaptcha" 
        sitekey="6LdwYxQaAAAAAEgPWLD9hV73x3S8GfWe7qD0tLkr"
        :loadRecaptchaScript="true"
        size="invisible"
        @verify="onCaptchaVerified"
        @expired="onCaptchaExpired"
      />      
    </template>

  </div>
</template>

<script>
import ButtonDefault from "@/components/shared/buttons/button-default.vue";
import { required, minLength, email } from "vuelidate/lib/validators";
import QuotationService from "@/services/quotation/quotation-service.js";
import { goToElement } from "@/mixin/go-to-element-mixin.js";
import { sendEventAnalytics } from "@/mixin/send-event-analytics.js";


const InputText = () =>
  import(
    /* webpackChunkName: "InputText" */ "@/components/shared/input/input-text.vue"
  );

const InputTextArea = () =>
  import(
    /* webpackChunkName: "InputTextArea" */ "@/components/shared/input/input-textarea.vue"
  );
  
const VueRecaptcha = () =>
  import(
    /* webpackChunkName: "VueRecaptcha" */ "vue-recaptcha"
  );

const PageAlert = () =>
  import(
    /* webpackChunkName: "PageAlert" */ "@/components/shared/alert/page-alert.vue"
  );
const QuotationContacts = () =>
  import(
    /* webpackChunkName: "QuotationContacts" */ "@/components/cotacao/quotation-contacts.vue"
  );

export default {
  mixins: [goToElement, sendEventAnalytics],
  components: {
    ButtonDefault,
    InputText,
    InputTextArea,
    VueRecaptcha,
    PageAlert,
    QuotationContacts,
  },

  data: function () {
    return {
      inputName: "",
      inputEmail: "",
      inputPhone: "",
      inputDescription: "",
      loading: false,
      formSuccess: false,
      serverError: "",
    };
  },

  validations: {
    inputName: {
      required,
      minLength: minLength(2),
    },
    inputEmail: {
      required,
      email,      
    },
    inputPhone: {
      required,
      minLength: minLength(8),
    },
    inputDescription: {
      required,          
      minLength: minLength(10),
    },
  },
  
  methods: {

    sendQuotation: function () {
      
      this.loading = true;
      this.$refs.recaptcha.execute();
      
/*       let self = this;

      self.loading = true;
      QuotationService.quotation(self.inputName, self.inputEmail, self.inputPhone, self.inputDescription)
        .then(function () {          
          self.loading = false;
          self.formSuccess = true;
          setTimeout(function(){             
            self.goToElement('response-message', 'start')
          }, 500);
        })
        .catch(function (error) {
          console.log(error, 'error');
          self.loading = false;
          self.formSuccess = false;
        }); */
    },

    onCaptchaVerified: function (recaptchaToken) {
      const self = this;
      self.loading = true;

      self.$refs.recaptcha.reset();

      QuotationService.quotation(
        self.inputName,
        self.inputEmail,
        self.inputPhone,
        self.inputDescription,
        recaptchaToken,
      ).then(() => {
        self.formSuccess = true;
        self.serverError = "";

        self.sendEventAnalytics(
          'cotacao-formulario',
          'fluxo', 
          'cotacao formulario enviado',
          'cotacao formulario'
        );

        self.$gtm.trackEvent({
          event: "quotation_form_submission"
        });

        setTimeout(function(){             
          self.goToElement('response-message', 'start')
        }, 500);        
      }).catch((err) => {
        self.serverError = getErrorMessage(err);
        console.log(self.serverError);
        self.formSuccess = false;

        self.sendEventAnalytics(
          'cotacao-formulario-erro',
          'fluxo', 
          'cotacao formulario erro',
          'cotacao formulario erro'
        );   

        self.$gtm.trackEvent({
          event: "quotation_form_error"
        });

        //helper to get a displayable message to the user
        function getErrorMessage(err) {
          let responseBody;
          responseBody = err.response;
          if (!responseBody) {
            responseBody = err;
          }
          else {
            responseBody = err.response.data || responseBody;
          }
          return responseBody.message || JSON.stringify(responseBody);
        }

      }).then(() => {
        self.loading = false;        
      });

    },

    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    }
  }
};

</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.cotacao-container {

  @media (min-width: $screenLarge) {
    display: flex;
  }
}

.contacts-container {
  @media (min-width: $screenLarge) {
    width: 415px;
  }  
}


// form

.form-cotacao-container {

  @media (min-width: $screenLarge) {
    width: 480px;
    max-width: 480px;
  }

  margin: auto;
}


.form-group {
  margin-bottom: 1rem;
}

.form-action {
  margin-bottom: 2rem;
  
  button:first-of-type {
    margin-left: 0;
  }

}

.atendimento-local {
  max-width: 500px;
  margin: auto;
  font-size: .875rem;
}

/* .form-group--alert, .form-group--error {
    animation-name: shakeError;
    animation-fill-mode: forwards;
    animation-duration: .6s;
    animation-timing-function: ease-in-out;
}

.form-group--error input, .form-group--error textarea, .form-group--error input:focus, .form-group--error input:hover {
    border-color: red;
}

.form-group--error + .form-group__message, .form-group--error + .error {
    display: block;
    color: red;
}

.form-group__message, .error {
    font-size: 0.75rem;
    line-height: 1;
    display: none;
    margin-left: 14px;
    margin-top: -1.6875rem;
    margin-bottom: 0.9375rem;
} */

.error {
  display: block;
  margin-top: .25rem;
  font-size: .75rem;
  color: $red-500;
}


/* .form-group--error {
  .form-control {
    border: 1px solid $red-500;
  }
}


.form-group--error {
    input[type='text'], input[type='email'], input[type='date'], select, input[type='password'] {        
        border: 1px solid $red-500;
    }
} */

.response-message {
  padding-top: 2.5rem;
  p {
    color: $secondary-text-color;
  }
}
.response-illustration {
  margin-bottom: 0.5rem;
  svg {
    width: 128px;
    height: 128px;
  }
}

.response-title {
  margin-bottom: 0.5rem;
  font-size: 1.125rem;
  font-weight: 500;

  @media (min-width: $screenLarge) {
    font-size: 1.3125rem;
  }
}
.response-message.success {
  .response-illustration {
    color: $green-500;
  }
}





</style>