<template>
  <section
    v-if="layoutInternalCreated"
    id="cotacao"
    class="section-content text-center"
  >
    <div class="container">
      <span v-if="!responseQuotationForm">
        <h2>Privacidade, segurança e modernidade</h2>
        <p class="introduction">
          Instalação de películas e adesivos para proteção solar, privacidade,
          decoração de móveis e ambientes
        </p>
        <QuotationFormContact @responseQuotation="responseQuotation" />
      </span>

      <div
        v-if="responseQuotationForm == 'success'"
        class="response-message success"
      >
        <div class="response-illustration">
          <svg class="icon icon-check-double">
            <use xlink:href="/images/icon-lib.svg#icon-check-double" />
          </svg>
        </div>
        <div class="response-title">Pedido de cotação recebido!</div>
        <p class="introduction">
          <strong>Anderson Sa</strong>, iremos responder o mais breve possível.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import LayoutInternal from "@/layouts/LayoutInternal.vue";
import QuotationFormContact from "@/components/cotacao/quotation-form-contact.vue";
import { layoutInternalCreated } from "@/mixin/layout-internal-created-mixin.js";

export default {

  mixins: [layoutInternalCreated],

  components: {
    QuotationFormContact,
  },

  data: function () {
    return {
      responseQuotationForm: "",
    };
  },

  created() {
    this.$emit(`update:layout`, LayoutInternal);
    this.$emit(`update:title`, "Faça uma Cotação");
    this.$emit(
      `update:introduction`,
      "Fale com nossos especialistas e solicite um orçamento sem compromisso para aplicar películas e adesivos"
    );
    this.$emit(`update:bgHeader`, "blue");
  },
  methods: {
    responseQuotation: function (response) {
      this.responseQuotationForm = response;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.introduction {
  color: $secondary-text-color;
}
</style>